import { useOauthLogin } from "../services/oauthLogin";

import styles from "../styles/Home.module.css";
import { useEffect } from "react";

const AuthRedirectLoader = () => {
  const { isOauthLoggedIn, handleRedirectResult } = useOauthLogin();
  useEffect(()=>{
    if(isOauthLoggedIn){
        window.location.href = "/";
    }
    async function init() {
        await handleRedirectResult();
        window.location.href = "/";
        
    }
    init();
  }, [])
  return (
    <div className={styles.grid}>
      <h1>Loading....</h1>
    </div>
  );
};

export default AuthRedirectLoader;
