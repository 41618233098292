import { WALLET_ADAPTERS } from "@web3auth/base";
import { useWeb3Auth } from "../services/web3auth";
import { useOauthLogin } from "../services/oauthLogin";

import styles from "../styles/Home.module.css";
import ConnectWithWeb3Auth from "./ConnectWeb3Auth";

const Main = () => {
  const { provider, logout, getUserInfo, getAccounts, getBalance, signMessage, signTransaction, signAndSendTransaction, web3Auth, chain, isLoading } = useWeb3Auth();
  const { loginWithOauth, isOauthLoggedIn, logout: logoutOauth } = useOauthLogin();
  const logoutFromWeb3Auth = async () => {
    await logout();
    logoutOauth();
  }
  const loggedInView = (
    provider ?
    <>
      <button onClick={getUserInfo} className={styles.card}>
        Get User Info
      </button>
      <button onClick={getAccounts} className={styles.card}>
        Get Accounts
      </button>
      <button onClick={getBalance} className={styles.card}>
        Get Balance
      </button>
      <button onClick={signMessage} className={styles.card}>
        Sign Message
      </button>
      {
        (web3Auth?.connectedAdapterName === WALLET_ADAPTERS.OPENLOGIN || chain === "solana") &&
        (<button onClick={signTransaction} className={styles.card}>
          Sign Transaction
      </button>)
      }
      <button onClick={signAndSendTransaction} className={styles.card}>
        Sign and Send Transaction
      </button>
      <button onClick={logoutFromWeb3Auth} className={styles.card}>
        Log Out
      </button>

      <div className={styles.console} id="console">
        <p className={styles.code}></p>
      </div>
    </> : <ConnectWithWeb3Auth />
  );

  const unloggedInView = (
    <button onClick={loginWithOauth} className={styles.card}>
      Login
    </button>
  );

  return isLoading ?
    <div className={styles.grid}>
     <h1>Loading....</h1>
   </div> :
   <div className={styles.grid}>{(isOauthLoggedIn || provider) ? loggedInView : unloggedInView}</div>
   
};

export default Main;
