import "./App.css";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WEB3AUTH_NETWORK_TYPE } from "./config/web3AuthNetwork";
import { CHAIN_CONFIG_TYPE } from "./config/chainConfig";
import styles from "./styles/Home.module.css";
import { Web3AuthProvider } from "./services/web3auth";
import {  OauthLoginProvider } from "./services/oauthLogin";

import Setting from "./components/Setting";
import Main from "./components/Main";
import AuthRedirectLoader from "./components/AuthRedirectLoader";

function App() {
  const [web3AuthNetwork, setWeb3AuthNetwork] = useState<WEB3AUTH_NETWORK_TYPE>("testnet");
  const [chain, setChain] = useState<CHAIN_CONFIG_TYPE>("binance");

  return (
    <div className={styles.container}>
      <Web3AuthProvider chain={chain} web3AuthNetwork={web3AuthNetwork}>
        <OauthLoginProvider>
          <h1 className={styles.title}>
            <a target="_blank" href="http://web3auth.io/" rel="noreferrer">
              Web3Auth
            </a>{" "}
            & Binance Example
          </h1>
          <Setting setNetwork={setWeb3AuthNetwork} setChain={setChain} chain={chain} />
          <BrowserRouter>
            <Routes>
              <Route path="/auth" element={<AuthRedirectLoader />}/>
              <Route path="/" element={<Main />}/>
            </Routes>
          </BrowserRouter>
          
        </OauthLoginProvider>
      </Web3AuthProvider>
      <footer className={styles.footer}>
        <a href="https://github.com/torusresearch/binance-oauth-example" target="_blank" rel="noopener noreferrer">
          Source code {"  "}
          <img className={styles.logo} src="/images/github-logo.png" alt="github-logo" />
        </a>
      </footer>
    </div>
  );
}

export default App;
