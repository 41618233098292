
export declare const WEB3AAUTH_NETWORK: {
  readonly MAINNET: "mainnet";
  readonly TESTNET: "testnet";
  readonly CYAN: "cyan";
  readonly DEVELOPMENT: "development";
};
export declare type WEB3AUTH_NETWORK_TYPE = typeof WEB3AAUTH_NETWORK[keyof typeof WEB3AAUTH_NETWORK];


export const idTokenExchangeUrl = "https://api.binance-demo.web3auth.io/tokenExchange/start";