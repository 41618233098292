import { LoginConfig } from "@toruslabs/openlogin-jrpc";

export const clientId = "BNji-y5KKOejPOOebOah54eIhEQjbPAlFIbI8af4q0F3eAWYhaFju47Nk_Nwe50PUMR20nnTAzB77tof_8rZx1E";

export const BINANCE_LOGIN_PROVIDER = "binance";

export const verifierDetails: LoginConfig = {
  [BINANCE_LOGIN_PROVIDER]: {
    verifier: "binance-example",
    typeOfLogin: "jwt",
    name: "Binance",
    clientId: "lTgQ7hJFnXivn5TKSfd60GsNWUjTU2ey",
    description: "Binance Login",
    logoDark: "https://public.bnbstatic.com/20190405/eb2349c3-b2f8-4a93-a286-8f86a62ea9d8.png",
    logoLight: "https://logos-download.com/wp-content/uploads/2018/04/Binance_logo_coin.png",
    mainOption: true,
    jwtParameters: {
      domain: "https://torus.au.auth0.com",
      connection: "Binance",
    },
  },
};
