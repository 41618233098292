import { useWeb3Auth } from "../services/web3auth";
import { useOauthLogin } from "../services/oauthLogin";

import styles from "../styles/Home.module.css";

const ConnectWithWeb3Auth = () => {
  const { login } = useWeb3Auth();
  const { accessToken, getIdTokenFromAccessToken, logout } = useOauthLogin();
  const connect = async ()=>{
    const idToken = await getIdTokenFromAccessToken(accessToken)
    await login(idToken);
  }
  return (
    <div className={styles.grid}>
      <div className={styles.console} id="console">
        <p className={styles.code}>
          You are successfully logged In With Binance oauth.
        </p>
      </div>
      <button onClick={connect} className={styles.card}>
        Connect Web3Auth
      </button>

      <button onClick={logout} className={styles.card}>
        Logout
      </button>
    </div>
  );
};

export default ConnectWithWeb3Auth;
