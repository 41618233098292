import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useState, useCallback } from "react";
import CustomAuth, { AGGREGATE_VERIFIER } from "@toruslabs/customauth";
import { idTokenExchangeUrl } from "../config/web3AuthNetwork";
import { clientId } from "../config/verifierDetails";

export interface IOauthLoginContext {
  torus: CustomAuth | null;
  isLoading: boolean;
  isOauthLoggedIn: boolean;
  idToken: string;
  accessToken: string;
  getIdTokenFromAccessToken: (accessToken: string) => Promise<string>;
  loginWithOauth: () => Promise<void>;
  handleRedirectResult: () => Promise<void>;
  logout: () => void,
}

export const OauthLoginContext = createContext<IOauthLoginContext>({
  torus: null,
  isLoading: false,
  isOauthLoggedIn: false,
  idToken: "",
  accessToken: "",
  getIdTokenFromAccessToken: async () => "",
  loginWithOauth: async () => {},
  handleRedirectResult: async () => {},
  logout: () => {},
});

export function useOauthLogin(): IOauthLoginContext {
  return useContext(OauthLoginContext);
}

interface IOauthLoginState {}

interface IOauthLoginProps {
  children?: ReactNode;
}

export const OauthLoginProvider: FunctionComponent<IOauthLoginState> = ({ children }: IOauthLoginProps) => {
  const [torus, setTorus] = useState<CustomAuth | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [idToken, setIdToken] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [isOauthLoggedIn, setIsLoggedIn] = useState(false);

  const getHashQueryParams = async (replaceUrl = false): Promise<Record<string, string>> => {
    const result: Record<string, string> = {};
  
    const url = new URL(window.location.href);
    url.searchParams.forEach((value, key) => {
      if (key !== "result") {
        result[key] = value;
      }
    });
    const queryResult = url.searchParams.get("result");
    if (queryResult) {
      try {
        const queryParams = JSON.parse(window.atob(queryResult));
        Object.keys(queryParams).forEach((key) => {
          result[key] = queryParams[key];
        });
      } catch (error) {
        console.error(error);
      }
    }

    const hash = url.hash.substring(1);
    const hashUrl = new URL(`${window.location.origin}/?${hash}`);
    hashUrl.searchParams.forEach((value, key) => {
      if (key !== "result") {
        result[key] = value;
      }
    });
    const hashResult = hashUrl.searchParams.get("result");

    if (hashResult) {
      try {
        const hashParams = JSON.parse(window.atob(hashResult));
        Object.keys(hashParams).forEach((key) => {
          result[key] = hashParams[key];
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (replaceUrl) {
      const cleanUrl = window.location.origin + window.location.pathname;
      window.history.replaceState(null, "", cleanUrl);
    }
    return result;
  }


  const setRedirectResult = useCallback(async (): Promise<boolean> => {
    const result = await getHashQueryParams();
    if (!result?.access_token) return false;
    const idToken = await getIdTokenFromAccessToken(result.access_token);
    setAccessToken(result.access_token);
    setIdToken(idToken);
    setIsLoggedIn(true);
    window.sessionStorage.setItem("binance_example_accessToken", result.access_token);
    return true;
  }, []);


  const checkIfAlreadyLoggedIn = useCallback(async (): Promise<boolean> => {
    const existingAccessToken = window.sessionStorage.getItem("binance_example_accessToken");
    if (!existingAccessToken) return false;
    const idToken = await getIdTokenFromAccessToken(existingAccessToken);
    setAccessToken(existingAccessToken);
    setIdToken(idToken);
    setIsLoggedIn(true);
    return true;
  }, []);

  useEffect(() => {
    async function init() {
      try {
        if (isOauthLoggedIn) return;
        setIsLoading(true);
        const torus = new CustomAuth({
          baseUrl: window.location.origin,
          redirectPathName: "auth",
          uxMode: "redirect",
          network:"cyan",
          enableLogging:true,
          locationReplaceOnRedirect: true,
        
          // v2
          metadataUrl: "https://metadata.tor.us",
          enableOneKey: true,
        });

        await torus.init({ skipSw: true });
        setTorus(torus);
        await checkIfAlreadyLoggedIn();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, []);

  const handleRedirectResult = async ()=>{
    await setRedirectResult();
  }

  const getIdTokenFromAccessToken = async (accessToken: string): Promise<string> => {
    const res = await fetch(idTokenExchangeUrl, { method:  "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
     body: JSON.stringify({
      accessToken, 
      clientId
    })});
    const data = await res.json();
    if (!data.idToken)  {
      throw new Error("Failed to fetch user's id token");
    }
    return data.idToken
  }

  const loginWithOauth = async () => {
    if (!torus) {
      console.log("oauth login is not initialized yet");
      uiConsole("oauth login is not initialized yet");
      return;
    }
    
    await torus.triggerAggregateLogin(
      {
        aggregateVerifierType: AGGREGATE_VERIFIER.SINGLE_VERIFIER_ID,
        verifierIdentifier: "tkey-binance-cyan",
        subVerifierDetailsArray: [
          {
            typeOfLogin: "jwt",
            verifier: "torus",
            clientId: "lTgQ7hJFnXivn5TKSfd60GsNWUjTU2ey",
            jwtParams: {
              domain: "https://torus.au.auth0.com",
              connection: "Binance",
              scope: "openid profile email",
            },
          },
        ],
      }
    )
  };

  const logout = () => {
    window.sessionStorage.removeItem("binance_example_accessToken");
    setIdToken("");
    setAccessToken("");
    setIsLoggedIn(false);
  }

  const uiConsole = (...args: unknown[]): void => {
    const el = document.querySelector("#console>p");
    if (el) {
      el.innerHTML = JSON.stringify(args || {}, null, 2);
    }
  };

  const contextProvider = {
    torus,
    isLoading,
    loginWithOauth,
    logout,
    idToken,
    accessToken,
    isOauthLoggedIn,
    handleRedirectResult,
    getIdTokenFromAccessToken,
  };
  return <OauthLoginContext.Provider value={contextProvider}>{children}</OauthLoginContext.Provider>;
};

